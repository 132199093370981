import React, { useState } from 'react';

interface LogoProps {
    onClose(): void;
}

const Logo: React.FC<LogoProps> = ({ onClose: onClick }) => {
    return (
        <div className="screen" onClick={onClick}>
            <img className="logo" src="media/logo.png" />
        </div>
    );
};

export default Logo;
