import React, { useState } from 'react';

interface FriendsProps {
    onClose(): void;
}

var isClosed = false;

const Friends: React.FC<FriendsProps> = ({ onClose }) => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [organization, setOrganization] = useState('');
    const [email, setEmail] = useState('');
    const [done, setDone] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);

    const isValid = !!(email && (firstname || lastname));

    const reset = () => {
        setFirstname('');
        setLastname('');
        setOrganization('');
        setEmail('');
        setError('');
    };

    const showSuccess = () => {
        setDone(true);
        reset();

        isClosed = false;
        setTimeout(() => {
            if (!isClosed) {
                isClosed = true;
                setDone(false);
                onClose();
            }
        }, 5000);
    };

    const showError = () => {
        setError('An error occured communicating with the server, please try again.');
    };

    const submit = async () => {
        setLoading(true);
        try {
            const result = await fetch('/api/friend', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({ firstname, lastname, organization, email })
            });

            if (result.ok) {
                const data = await result.json();
                setLoading(false);
                if (data === true) {
                    console.log(data);
                    showSuccess();
                } else {
                    showError();
                }
            } else {
                setLoading(false);
                showError();
            }
        } catch {
            setLoading(false);
            showError();
        }
    };

    const close = () => {
        isClosed = true;
        setDone(false);
        reset();
        onClose();
    };

    if (done)
        return (
            <div className="canvas">
                <h1>Become a Friend of the Lab</h1>
                <p>Thank you for signing up to become a friend of the lab!</p>
                <p>You'll hear from us soon.</p>
                <div className="form">
                    <button className="bggreen" onClick={close}>
                        Ok
                    </button>
                </div>
            </div>
        );

    return (
        <div className="canvas">
            <h1>Become a Friend of the Lab</h1>
            <p>
                By becoming a friend of the UNSW Business AI Lab, you will have the option to be involved in our experiments, be invited to events and hear news
                about the lab.
            </p>
            <p>There is no obligation. We will not share or use your email for any other purpose. You can leave at any time.</p>
            <div className="form">
                <label>
                    <div className="formkey">First name: </div>
                    <input type="text" autoComplete="off" value={firstname} onChange={e => setFirstname(e.target.value)} />
                </label>
            </div>
            <div className="form">
                <label>
                    <div className="formkey">Last name: </div>
                    <input type="text" autoComplete="off" value={lastname} onChange={e => setLastname(e.target.value)} />
                </label>
            </div>
            <div className="form">
                <label>
                    <div className="formkey">Organisation: </div>
                    <input type="text" autoComplete="off" value={organization} onChange={e => setOrganization(e.target.value)} />
                </label>
            </div>
            <div className="form">
                <label>
                    <div className="formkey">Email address: </div>
                    <input type="text" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} />
                </label>
            </div>
            <div className="form">{error}</div>
            <div className="form">
                <button className="bgpink" onClick={close}>
                    Cancel
                </button>
                <button className="bggreen" disabled={isLoading} onClick={submit}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </div>
        </div>
    );
};

export default Friends;
