import React, { useState } from 'react';

interface PuzzleProps {
    onClose(): void;
}

interface Guess {
    value: string[];
    outcome: boolean;
}

var isClosed = false;

const Puzzle: React.FC<PuzzleProps> = ({ onClose }) => {
    const [guesses, setGuesses] = useState<Guess[]>([]);
    const [one, setOne] = useState('');
    const [two, setTwo] = useState('');
    const [three, setThree] = useState('');
    const [know, setKnow] = useState(false);

    const currentValue = [one, two, three];
    const validation = currentValue.map(item => Number(item ? item : NaN));
    const isValid = validation.every(item => !isNaN(item) && isFinite(item));

    const reset = () => {
        setOne('');
        setTwo('');
        setThree('');
    };

    const check = () => {
        if (isValid) {
            const outcome = validation[2] > validation[1] && validation[1] > validation[0];
            setGuesses([...guesses, { value: currentValue, outcome }]);
            reset();
        }
    };

    const knowRule = () => {
        isClosed = false;
        setTimeout(() => {
            if (!isClosed) {
                isClosed = true;
                onClose();
            }
        }, 5000);
        setKnow(true);
        reset();
        setGuesses([]);
    };

    const close = () => {
        if (!isClosed) {
            isClosed = true;
            onClose();
        }
    };

    if (know)
        return (
            <div className="canvas">
                <h1>Puzzle</h1>
                <p>To find out whether you have solved the problem, explain your rule to the UNSW Business AI Lab team.</p>
                <div className="rule">
                    <button className="guessbutton" onClick={close}>
                        Ok
                    </button>
                </div>
            </div>
        );

    return (
        <div className="canvas">
            <h1>Puzzle</h1>
            <p>This page enforces a hidden rule. The sequence 2, 4, 8 follows the rule. Other sequences do not follow the rule.</p>
            <div className="rule ok">
                <span className="value">2</span>
                <span className="value">4</span>
                <span className="value">8</span>
                {' follows the rule'}
            </div>
            <p>Can you find the rule? Enter three numbers into the boxes below and they will be checked against the rule.</p>
            <p>Click "Done" when you think you have the answer.</p>
            {guesses.map(guess => (
                <div className={guess.outcome ? 'rule ok' : 'rule nok'}>
                    <span className="value">{guess.value[0]}</span>
                    <span className="value">{guess.value[1]}</span> <span className="value">{guess.value[2]}</span>
                    {guess.outcome ? ' follows the rule' : ' does not follow the rule'}
                </div>
            ))}
            <div className="rule">
                <input type="text" autoComplete="off" value={one} onChange={e => setOne(e.target.value)} />
                <input type="text" autoComplete="off" value={two} onChange={e => setTwo(e.target.value)} />
                <input type="text" autoComplete="off" value={three} onChange={e => setThree(e.target.value)} />
                <button className={isValid ? 'guessbutton' : 'guessbutton invalid'} disabled={!isValid} onClick={check}>
                    Check
                </button>
            </div>
            <div className="rule">
                <button className="guessbutton" onClick={knowRule}>
                    Done
                </button>
            </div>
        </div>
    );
};

export default Puzzle;
