import React, { useState } from 'react';
import Puzzle from './Puzzle';
import Friends from './Friends';
import Logo from './Logo';

var activated = 0;

const Menu: React.FC<{}> = () => {
    const [isMenu, setMenu] = useState(false);
    const [isPuzzle, setPuzzle] = useState(false);
    const [isFriend, setFriend] = useState(false);

    if (!isMenu)
        return (
            <Logo
                onClose={() => {
                    setMenu(true);
                    activated++;
                    const same = activated;
                    setTimeout(() => {
                        if (activated == same && !isPuzzle && !isFriend) {
                            setMenu(false);
                        }
                    }, 5000);
                }}
            />
        );
    if (isPuzzle)
        return (
            <Puzzle
                onClose={() => {
                    setPuzzle(false);
                    setMenu(false);
                }}
            />
        );
    if (isFriend)
        return (
            <Friends
                onClose={() => {
                    setFriend(false);
                    setMenu(false);
                }}
            />
        );

    return (
        <>
            <button
                className="menu bgpink"
                onClick={() => {
                    activated++;
                    setPuzzle(true);
                }}>
                Solve a Puzzle
            </button>
            <button
                className="menu bgorange"
                onClick={() => {
                    activated++;
                    setFriend(true);
                }}>
                Become a Friend of the Lab
            </button>
        </>
    );
};

export default Menu;
