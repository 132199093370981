import React from 'react';
import { createRoot } from 'react-dom/client';
import Menu from './pages/Menu';

const root = document.getElementById('root');
if (root) {
    createRoot(root).render(
        <React.StrictMode>
            <Menu />
        </React.StrictMode>
    );
}
